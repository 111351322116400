<template>
  <cz-menu-button
    v-if="vendor"
    v-bind="$attrs"
    v-on="$listeners"
    :menu-max-width="350"
    flat
    color="info"
    :button-title="vendor.businessName"
    :menuItems="vendorMenuItems"
    :button-icon-src="mdiAccountCircleOutline"
    with-paddings
  />
</template>

<script>
import { CzMenuButton } from '@/components';
import { mdiAccountCircleOutline } from '@mdi/js';
export default {
  name: 'VendorInfoMenu',
  components: {
    CzMenuButton
  },
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  computed: {
    vendorMenuItems() {
      if (!this.vendor) {
        return [];
      }

      return [
        {
          title: this.$t('customer.orders.vendorName'),
          subtitle: this.vendor.businessName
        },
        {
          title: this.$t('customer.orders.vendorId'),
          subtitle: this.vendor._id
        },
        {
          title: `${this.$t('vendor.onboarding.primaryCategory')}`,
          subtitle: `${this.vendor.primaryCategory.name}`
        },
        {
          title: `${this.$t('vendor.onboarding.secondaryCategory')}`,
          subtitle: this.vendorSubCategories
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonName'),
          subtitle: this.vendor.contactPersonName
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonEmail'),
          subtitle: this.vendor.contactEmail
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonPhone'),
          subtitle: this.vendor.contactPhoneNumber
        }
      ];
    },
    vendorSubCategories() {
      let value = '';
      this.vendor.secondaryCategory.forEach((item) => {
        if (!value) {
          value = item.name;
        } else {
          value = `${value}, ${item.name}`;
        }
      });

      return value;
    }
  },
  data() {
    return {
      mdiAccountCircleOutline
    };
  }
};
</script>

<style></style>
